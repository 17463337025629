<template>
    <div class="p-grid p-jc-center  no_margin" >
        <div class="p-col-9 p-md-9 p-lg-9 p-grid p-nogutter">
            <div class="p-col-12 p-grid p-field text-left">
                <h1>{{ $t('createOrganizationView.title') }}</h1>
                
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:180px" for="fieldId">{{$t('createOrganizationView.organizationName')}}</label>
                <InputText class="p-col" style="padding: 0.5rem 0.5rem;" v-model="OrganizationName" id="fieldId" type="text" :placeholder="$t('createOrganizationView.OrganizationNamePlaceholder')" />
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:180px" for="departmentId">{{$t('createOrganizationView.departmentName')}}</label>
                <InputText class="p-col" style="padding: 0.5rem 0.5rem;" v-model="DepartmentName" id="departmentId" type="text" :placeholder="$t('createOrganizationView.DepartmentNamePlaceholder')" />
            </div>
            <div class="p-col-12 text-right">
                <Button class="p-button-outlined p-button-info" @click="addOrganization" v-bind:disabled="isProcessing()" >{{$t('createOrganizationView.register')}}</Button>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import http from '../mixins/api';
import Processing from '@/mixins/processing'



export default {
    name: 'CreateOrg',
    mixins: [Processing],
    components: {
        InputText,
        Button,
    },
    data: function() {
        return {
            OrganizationName: null,
            DepartmentName: null,
        }
    },
    methods: {
        async addOrganization() {
            this.startProcessing()
            const OrganizationName = this.OrganizationName
            const DepartmentName = this.DepartmentName
            if(OrganizationName && DepartmentName) {
                if(OrganizationName && OrganizationName.length > this.$constants.maxTextLength) {
                    this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.invalidMaxTextLength"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                } else if(DepartmentName && DepartmentName.length > this.$constants.maxTextLength) {
                    this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.invalidMaxTextLength"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                } else {
                    const res = await http.post("/user/AddOrganization", {OrganizationName: OrganizationName, DepartmentName: DepartmentName})
                    if(res.Success) {
                        this.$toast.add({severity:'success', summary: this.$i18n.tc("invited.registedCompany"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                    }
                    this.OrganizationName = null;
                    this.DepartmentName = null;
                }
            } else {
                this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.anyBlank"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.endProcessing()
        },
    }
}
</script>


